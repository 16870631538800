import Layout from "../components/layout"
import { ExternalLink, FileLink, InternalLink } from "../pages/research"
import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import SEO from "../components/seo"

export default function PaperDetails({data}) {
	const { html, frontmatter } = data.markdownRemark;

	return (
		<Layout omitTitle={false}>
		{/*<div>*/}
			<SEO title={frontmatter.title} />
			{/*<div className="sticky-top h1 col-md-8 col-10 mx-auto text-center">{frontmatter.title}</div>*/}
			<div className="h1 col-md-8 col-10 mx-auto text-center">{frontmatter.title}</div>

			<div className="container my-3">
				{/*<div className="row">*/}
				{/*	<div className="col-md-8 col-12 mx-auto text-center h1">*/}
				{/*		<h1 className="sticky-top">*/}
				{/*			{frontmatter.title}*/}
				{/*		</h1>*/}
				{/*	</div>*/}
				{/*</div>*/}
				<div className="row my-3">
					<div className="col-md-8 col-10 mx-auto  text-center lead"
						 dangerouslySetInnerHTML={{ __html: frontmatter.authors }} />
				</div>
				<div className="row my-3">
					<div className="col-md-6 col-12 mx-auto text-center">
						<FileLink element={frontmatter.bibtex} tag={"BibTex"} />
						<FileLink element={frontmatter.pdf} tag={"PDF"} />
						<ExternalLink element={frontmatter.arxiv} tag={"Arxiv"} />
						<ExternalLink element={frontmatter.youtube} tag={"Video"} />
						<ExternalLink element={frontmatter.code} tag={"Code"} />
					</div>
				</div>

				<div className="text-center lead my-3">
					<em>{frontmatter.conference_short} ({frontmatter.year})</em>
				</div>

				<div className="col-md-10 col-12 p-1 mx-auto"
					 dangerouslySetInnerHTML={{ __html: html }} />
			</div>
		{/*</div>*/}
		</Layout>
	)
}

export const query = graphql` 
	query PaperDetails($slug: String) {
	  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
		frontmatter {
		  title
		  conference
		  conference_short
		  year
		  authors
		  pdf 
		  bibtex 
		  code
		  arxiv
		  youtube
		  project { 
		  	id
		  	name
		  	ext
		  }
		}
		html
	  }
	}

`